import { HttpStatusCode } from '@solidjs/start';
import { createAsync, useAction, useParams, useSearchParams } from '@solidjs/router';
import {
	Button,
	DialogContent,
	Dialog,
	Errors,
	FieldDescription,
	Form,
	Label,
	Link,
	Radio,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	DialogTrigger,
	TextLink,
	Container,
	Page,
	HorizontalRule,
} from '@troon/ui';
import { For, Show, createSignal, useContext, Suspense, Switch, Match, createUniqueId } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { createStore, produce } from 'solid-js/store';
import { Icon } from '@troon/icons';
import { captureException } from '@sentry/solidstart';
import { gql, mutationAction, TroonCardSubscriptionProductType, useMutation } from '../../../../../graphql';
import { dayTimeToDate, formatDateTime } from '../../../../../modules/date-formatting';
import { FacilityCtx } from '../../../../../providers/facility';
import { FacilityHeader } from '../../../../../components/facility/header';
import { Grid, GridMain, GridSidebar } from '../../../../../components/layouts/grid';
import { PaymentInfo } from '../../../../../components/payment-info';
import { cardBrandToComponent, cardBrandToString } from '../../../../../modules/credit-cards';
import { AddCard } from '../../../../../partials/add-card';
import { useUser } from '../../../../../providers/user';
import { AuthFlow } from '../../../../../partials/auth/auth';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { createNumberFormatter, holesFormatter } from '../../../../../modules/number-formatting';
import { useUtmParams } from '../../../../../providers/utm';
import ConfirmSubscription from '../../../../../components/confirm-subscription';
import { usePersisted } from '../../../../../providers/persistence-store';
import { getConfigValue } from '../../../../../modules/config';
import { AccessCardUpsellRate } from './components/access-upsell-rate';
import type { SetStoreFunction } from 'solid-js/store';
import type { AccessorWithLatest } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';
import type { CalendarDayTime, CourseTeeTimeRate, CreditCard, TeeTimePaymentInfoQuery } from '../../../../../graphql';

export default function ReserveTeeTimeWrapper() {
	const facility = useContext(FacilityCtx);
	const [searchParams] = useSearchParams<{
		subscriptionId?: string;
		productId?: string;
	}>();
	const [confirmSubscriptionOpen, setConfirmSubscriptionOpen] = createSignal(
		!!(searchParams.subscriptionId && searchParams.productId),
	);

	return (
		<Page>
			<Container>
				<Show when={facility()?.facility}>
					{(facility) => <Title>{`Book tee times | ${facility()?.name} | Troon`}</Title>}
				</Show>
				<Meta name="robots" content="noindex" />
				<Meta name="googlebot" content="noindex" />

				<FacilityHeader
					// @ts-expect-error TODO: need to fix fragment masking
					facility={facility()?.facility ?? {}}
					showHero
				/>

				<h1 class="sr-only">Book your tee time</h1>

				<div class="sm:hidden">
					<HorizontalRule />
				</div>

				<ReserveTeeTime />

				<Show when={searchParams.subscriptionId && searchParams.productId}>
					<Dialog
						key="subscription-confirmation"
						defaultOpen
						open={confirmSubscriptionOpen()}
						onOpenChange={setConfirmSubscriptionOpen}
					>
						<DialogContent>
							<ConfirmSubscription
								subscriptionId={searchParams.subscriptionId!}
								productId={searchParams.productId!}
								onContinue={() => {
									setConfirmSubscriptionOpen(false);
								}}
								continueText="Continue booking"
							/>
						</DialogContent>
					</Dialog>
				</Show>
			</Container>
		</Page>
	);
}

function ReserveTeeTime() {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const facility = useContext(FacilityCtx);
	const [searchParams] = useSearchParams<{
		rateId?: string;
		players?: string;
		triggerId?: string;
		subscriptionId?: string;
		productId?: string;
	}>();
	const [store, setStore] = createStore({
		rateId: searchParams.rateId || null,
		players: searchParams.players
			? isNaN(parseInt(searchParams.players, 10))
				? null
				: parseInt(searchParams.players, 10)
			: null,
	});

	const receipt = createAsync(
		() =>
			getPaymentInfo({
				teeTimeId: params.teeTimeId,
				rateId: store.rateId,
				players: store.players,
			}),
		{ deferStream: true },
	);

	return (
		<Show
			when={receipt.latest?.info}
			fallback={
				<>
					<HttpStatusCode code={404} />
					<div class="flex flex-col gap-8">
						<p>The requested tee time is no longer available.</p>
						<div class="flex flex-row">
							<Button as={Link} href={`/course/${facility()?.facility.slug}/reserve-tee-time`} class="shrink grow-0">
								See available tee times
							</Button>
						</div>
					</div>
				</>
			}
		>
			<ReserveTeeTimeFound receipt={receipt} store={store} setStore={setStore} />
		</Show>
	);
}

type TTStore = { rateId: string | null; players: number | null };

function ReserveTeeTimeFound(props: {
	receipt: AccessorWithLatest<TeeTimePaymentInfoQuery | undefined>;
	store: TTStore;
	setStore: SetStoreFunction<TTStore>;
}) {
	const [searchParams] = useSearchParams<{
		rateId?: string;
		players?: string;
		triggerId?: string;
		subscriptionId?: string;
		productId?: string;
	}>();
	const facility = useContext(FacilityCtx);
	const utm = useUtmParams();
	const numberFormatter = createNumberFormatter();
	const [showAddPayment, setShowAddPayment] = createSignal(false);
	const [showAuth, setShowAuth] = createSignal(false);
	const [showAuthLink, setShowAuthLink] = createSignal(false);
	const authDialogId = createUniqueId();
	const [showAddCard, setShowAddCard] = createSignal(false);

	const [persisted] = usePersisted();

	let form: HTMLFormElement;

	const [cardId, setCardId] = createSignal<string>();
	const mutation = reserve(async () => {
		if (persisted.rwg_token && persisted.rwg_token.expires > Date.now() && persisted.rwg_merchant) {
			const merchant_changed = facility()?.facility.slug === persisted.rwg_merchant.value ? 2 : 1;
			fetch(getConfigValue('GOOGLE_ACTIONS_ENDPOINT'), {
				method: 'POST',
				body: JSON.stringify({
					conversion_partner_id: getConfigValue('GOOGLE_ACTIONS_PARTNER_ID'),
					rwg_token: persisted.rwg_token.value,
					merchant_changed: `${merchant_changed}`,
				}),
			});
		}
	});
	const reserveAction = useMutation(mutation);
	const triggerFormAction = useAction(reserveAction);

	const user = useUser();
	const ccInfo = createAsync(() => (!user() ? Promise.resolve(undefined) : getCreditCards({})));

	return (
		<Show when={props.receipt.latest?.info}>
			{(data) => (
				<Form document={reserveMutation} action={reserveAction} method="post" ref={form!}>
					<input type="hidden" name="__facilitySlug" value={facility()?.facility.slug} />
					<input type="hidden" name="__value" value={props.receipt.latest?.info.paymentInfo.receipt.total.value} />
					<For each={Object.entries(utm())}>{([key, val]) => <input type="hidden" name={key} value={val} />}</For>
					<Suspense>
						<input type="hidden" name="reserveId" value={data().reserveId} />
						<Show when={searchParams.triggerId}>
							<input type="hidden" name="triggerId" value={searchParams.triggerId} />
						</Show>
					</Suspense>
					<Grid>
						<GridMain class="flex flex-col gap-4">
							<h2 class="text-xl font-semibold">Reservation details</h2>

							<ul class="flex flex-col gap-3">
								<li class="flex items-center gap-2">
									<Icon name="calendar" class="size-6 text-brand" />
									<span>{formatDateTime(dayTimeToDate(data().rate.dayTime as CalendarDayTime))}</span>
								</li>
								<li class="flex items-center gap-2">
									<Icon name="map-pin" class="size-6 text-brand" />
									<span>{data().course.name}</span>
								</li>
								<Suspense>
									<li class="flex items-center gap-2">
										<Icon name="flag" class="size-6 text-brand" />
										<span>{holesFormatter(numberFormatter(), data().holes)} holes</span>
									</li>
									<Show when={data().rate.cartIncluded}>
										<li class="flex items-center gap-2">
											<Icon name="golf-cart" class="size-6 text-brand" />
											<span>Cart included</span>
										</li>
									</Show>
								</Suspense>
							</ul>

							<Show when={data()?.courseNotes}>
								{(notes) => (
									<div class="mt-8 flex flex-col gap-2 rounded bg-neutral-100 p-4">
										<h3 class="text-sm font-semibold">Notes from the course:</h3>
										<p class="text-sm">{notes()}</p>
									</div>
								)}
							</Show>

							<hr class="my-4 border-neutral-500 md:my-6" />

							<RadioGroup name="__players" onSelect={(index) => props.setStore('players', parseInt(index, 10) + 1)}>
								<Label class="text-xl font-semibold">Players</Label>
								<RadioBar>
									<For each={new Array(4).fill(0)}>
										{(info, index) => (
											<RadioBarButton
												value={index()}
												checked={
													!props.store.players
														? data().rate.minPlayers === index() + 1
														: props.store.players === index() + 1
												}
												disabled={data().rate.minPlayers > index() + 1 || data().rate.maxPlayers <= index()}
											>
												<Label>{index() + 1}</Label>
											</RadioBarButton>
										)}
									</For>
								</RadioBar>
								<FieldDescription>
									<Switch>
										<Match when={data().rate.minPlayers > 1}>
											<p>
												The {data().rate.name} rate requires a minimum of {data().rate.minPlayers} players to book this
												tee time.
											</p>
										</Match>
										<Match when>
											<p>
												There {data().rate.maxPlayers > 1 ? 'are' : 'is'} {data().rate.maxPlayers} spot
												{data().rate.maxPlayers > 1 ? 's' : ''} available for this tee time.
											</p>
										</Match>
									</Switch>
								</FieldDescription>
							</RadioGroup>

							<Suspense>
								<Show
									when={
										[
											...data().availableRates.sort((a, b) => a.price.value - b.price.value),
											...(data().troonSubscriptionRate ? [data().troonSubscriptionRate] : []),
										] as Array<CourseTeeTimeRate>
									}
								>
									{(rates) => (
										<>
											<hr class="my-4 border-neutral-500 md:my-6" />
											<h2 class="text-xl font-semibold">Choose your rate</h2>
											<RadioGroup name="__rateId">
												<Label class="sr-only">Choose your rate</Label>
												<div class="flex flex-col gap-3">
													<For each={rates()}>
														{(rate) => (
															<Switch>
																<Match
																	when={
																		!(
																			!facility()?.facility.supportsTroonAccessCourseSiteUpsell &&
																			utm().campaign === 'course-booking-link' &&
																			utm().source === facility()?.facility.slug
																		) &&
																		rate.isTroonCardRate &&
																		rate.isAvailableToUser !== true
																	}
																>
																	<div class="flex flex-col gap-2">
																		<AccessCardUpsellRate
																			rate={rate}
																			isSelected={props.store.rateId === rate.id || data().rate.id === rate.id}
																			discounts={{
																				[TroonCardSubscriptionProductType.TroonAccess]:
																					data().troonSubscriptionRateDiscounts.troonAccess,
																				[TroonCardSubscriptionProductType.TroonAccessPlus]:
																					data().troonSubscriptionRateDiscounts.troonAccessPlus,
																			}}
																			original={rates().find((r) => r.name === 'Public')?.price}
																		/>
																		<Show when={!user() && data().troonSubscriptionRate}>
																			{(product) => (
																				<p>
																					Already have {product().name}?{' '}
																					<Dialog
																						key="reserve-login-signup-link"
																						open={showAuthLink()}
																						onOpenChange={setShowAuthLink}
																						id={authDialogId}
																					>
																						<DialogTrigger as={TextLink} href="/auth">
																							Log in
																						</DialogTrigger>
																						<DialogContent autoWidth noPadding noClose floatingClose>
																							<AuthFlow
																								onComplete={() => setShowAuthLink(false)}
																								headings={{ '/auth': 'Log in or sign up to continue booking' }}
																							/>
																						</DialogContent>
																					</Dialog>
																				</p>
																			)}
																		</Show>
																	</div>
																</Match>
																{/* IMPORTANT: this prop is NOT set on public rates. check strictly equal to false */}
																<Match when={rate.isAvailableToUser !== false}>
																	<div
																		// eslint-disable-next-line tailwindcss/no-arbitrary-value
																		class="cursor-pointer rounded border border-neutral p-4 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6"
																		onClick={() => {
																			props.setStore(
																				produce((s) => {
																					s.players = s.players
																						? Math.max(Math.min(rate.maxPlayers, s.players), rate.minPlayers)
																						: null;
																					s.rateId = rate.id;
																				}),
																			);
																		}}
																	>
																		<Radio
																			value={rate.id ?? 'asdf'}
																			checked={props.store.rateId === rate.id || data().rate.id === rate.id}
																		>
																			<Label class="flex flex-col gap-1 ps-2">
																				<span class="font-semibold">{rate.name}</span>
																				<span class="text-sm text-neutral-800">
																					{rate.price.displayValue} per player
																				</span>
																			</Label>
																		</Radio>
																	</div>
																</Match>
															</Switch>
														)}
													</For>
												</div>
											</RadioGroup>
										</>
									)}
								</Show>
							</Suspense>

							<Suspense>
								<Show
									when={(data().course.requiresCCAtBooking || data().paymentInfo.receipt.dueNow.value > 0) && user()}
								>
									<hr class="my-4 border-neutral-500 md:my-6" />
									<h2 class="text-xl font-semibold">Payment method</h2>
									<p class="text-sm text-neutral-700">
										This course requires a credit card to book a tee time. You will only be charged if you no show or
										cancel beyond the cancellation policy.
									</p>
									<RadioGroup name="creditCardId" onSelect={(value) => setCardId(value)}>
										<Label class="sr-only">Credit card</Label>
										<Show when={(ccInfo()?.creditCards ?? []).length}>
											<div class="flex flex-col gap-2 rounded py-2">
												<For each={ccInfo()?.creditCards ?? []}>
													{(card) => {
														const CardIcon = cardBrandToComponent[card.brand];
														return (
															<div
																// eslint-disable-next-line tailwindcss/no-arbitrary-value
																class="cursor-pointer rounded border border-neutral p-4 has-[:checked]:border-brand has-[:checked]:bg-brand-100 md:p-6"
																onClick={() => setCardId(card.id)}
															>
																<Radio value={card.id} checked={cardId() === card.id}>
																	<Label class="flex flex-row items-center gap-2">
																		<div class="w-10">
																			<CardIcon />
																		</div>
																		<div class="grow">
																			{cardBrandToString[card.brand]} ending in {card.lastFour}
																		</div>
																	</Label>
																</Radio>
															</div>
														);
													}}
												</For>
											</div>
										</Show>
									</RadioGroup>
									<div class="self-start">
										<Dialog key="reserve-add-payment-method" open={showAddPayment()} onOpenChange={setShowAddPayment}>
											<DialogTrigger appearance="transparent">+ Add payment method</DialogTrigger>
											<DialogContent header="Add payment method" headerLevel="h2">
												<AddCard
													onSuccess={(cardId) => {
														setCardId(cardId);
														setShowAddPayment(false);
													}}
												/>
											</DialogContent>
										</Dialog>
									</div>
								</Show>
							</Suspense>
						</GridMain>

						<GridSidebar>
							<div class="sticky top-24 flex flex-col gap-y-2 rounded border border-neutral-500 p-4 md:p-6">
								<PaymentInfo
									receipt={props.receipt()?.info.paymentInfo.receipt}
									rewardPointsEarned={props.receipt()?.info.paymentInfo.rewardPointsEarned}
									supportsTroonRewards={props.receipt()?.info.course.supportsTroonRewards}
								/>

								<Suspense>
									<Show when={props.receipt.latest}>
										<h3 class="text-lg font-semibold">
											<Icon name="square-warning" class="text-brand" /> Cancellation policy
										</h3>
										<p class="text-sm text-neutral-700">{data().cancellationInfo.displayMessage}</p>
									</Show>

									<Errors />

									<Switch>
										<Match when={!user()}>
											<>
												<Dialog
													key="reserve-login-signup"
													open={showAuth()}
													onOpenChange={setShowAuth}
													id={authDialogId}
												>
													<DialogTrigger>Log in to book</DialogTrigger>
													<DialogContent autoWidth noPadding noClose floatingClose>
														<AuthFlow
															onComplete={() => setShowAuth(false)}
															headings={{ '/auth': 'Log in or sign up to continue booking' }}
														/>
													</DialogContent>
												</Dialog>
											</>
										</Match>

										<Match
											when={
												(data().course.requiresCCAtBooking || data().paymentInfo.receipt.dueNow.value > 0) && !cardId()
											}
										>
											<Dialog key="reserve-add-payment-method" open={showAddCard()} onOpenChange={setShowAddCard}>
												<DialogTrigger type="button" disabled={!props.receipt()}>
													Book now
												</DialogTrigger>
												<DialogContent header="Add payment method" headerLevel="h2">
													<AddCardDialog
														cards={ccInfo()?.creditCards ?? []}
														onSuccess={(creditCardId) => {
															const data = new FormData(form);
															setCardId(creditCardId);
															data.append('creditCardId', creditCardId);
															setShowAddCard(false);
															triggerFormAction(data);
														}}
													/>
												</DialogContent>
											</Dialog>
										</Match>

										<Match
											when={
												!(data().course.requiresCCAtBooking || data().paymentInfo.receipt.dueNow.value > 0) ||
												((data().course.requiresCCAtBooking || data().paymentInfo.receipt.dueNow.value > 0) && cardId())
											}
										>
											<Button disabled={!props.receipt()} type="submit">
												Book now
											</Button>
										</Match>
									</Switch>
								</Suspense>
							</div>
						</GridSidebar>
					</Grid>
				</Form>
			)}
		</Show>
	);
}

function AddCardDialog(props: ComponentProps<typeof AddCard> & { cards: Array<CreditCard> }) {
	const [cardId, setCardId] = createSignal<string>();
	const [showForm, setShowForm] = createSignal(!props.cards.length);

	return (
		<div class="flex flex-col gap-y-4">
			<p class="text-sm">
				This course requires a credit card to book a tee time. You will only be charged if you no show or cancel beyond
				the cancellation policy.
			</p>

			<Switch>
				<Match when={!showForm()}>
					<RadioGroup name="creditCardId" onSelect={(value) => setCardId(value)}>
						<Label class="sr-only">Credit card</Label>
						<Show when={props.cards.length}>
							<div class="flex flex-col gap-2 rounded bg-neutral-100 px-4 py-2">
								<For each={props.cards}>
									{(card) => {
										const CardIcon = cardBrandToComponent[card.brand];
										return (
											<Radio value={card.id} checked={cardId() === card.id}>
												<Label class="flex flex-row items-center gap-2">
													<div class="w-10">
														<CardIcon />
													</div>
													<div class="grow">
														{cardBrandToString[card.brand]} ending in {card.lastFour}
													</div>
												</Label>
											</Radio>
										);
									}}
								</For>
							</div>
						</Show>
					</RadioGroup>
					<div class="self-start">
						<Button appearance="transparent" onClick={() => setShowForm(true)}>
							+ Add payment method
						</Button>
					</div>
					<Button
						onClick={() => {
							props.onSuccess!(cardId()!);
						}}
						disabled={!cardId()}
					>
						Book tee time
					</Button>
				</Match>
				<Match when={showForm()}>
					<AddCard {...props} buttonText={'Add card & book tee time'} />
				</Match>
			</Switch>
		</div>
	);
}

const reserveMutation = gql(`
mutation reserveTeeTime(
	$reserveId: String!
	$creditCardId: String
	$triggerId: String
	$campaign: String
	$source: String
	$medium: String
	$term: String
	$content: String
) {
	reserveTeeTime(
		reserveId: $reserveId
		creditCardId: $creditCardId
		teeTimeAlertTriggerId: $triggerId
		utmAttributes: { campaign: $campaign, source: $source, medium: $medium, term: $term, content: $content }
	) {
		id
		teeTimeId
		courseId
		state
		playerCount,
		holeCount
		includesCart
		dayTime {
			...DayTime
		}
	}
}
`);

const reserve = (onSuccess: () => Promise<void>) =>
	mutationAction(reserveMutation, {
		revalidates: ['home', 'allReservations'],
		redirect: (data) => `/reservation/${data?.reserveTeeTime.id}`,
		redirectOptions: { state: { confirmed: true } },
		toast: 'Your tee time has been confirmed!',
		onSuccess,
		track: {
			event: 'reserveTeeTime',
			transform: (data, res) => ({
				facilitySlug: data.get('__facilitySlug') as string,
				value: parseFloat(data.get('__value') as string),
				players: res?.reserveTeeTime.playerCount,
				courseId: res?.reserveTeeTime.courseId,
			}),
		},
	});

const paymentInfoQuery = gql(`
query teeTimePaymentInfo(
	$teeTimeId: String!
	$rateId: String
	$players: Int
) {
	info: courseTeeTimeReservationInfo(
		teeTimeId: $teeTimeId
		rateId: $rateId
		players: $players
	) {
		reserveId
		teeTime { ...TeeTime }
		courseNotes
		availableRates {
			id
			name
			dayTime { ...DayTime }
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price { ...Currency }
		}
		rate {
			id
			name
			dayTime { ...DayTime }
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price { ...Currency }
		}
		troonSubscriptionRate {
			id
			name
			dayTime { ...DayTime }
			isAvailableToUser
			isPrePaid
			isTroonCardRate
			isLocalCardRate
			holesOption
			cartIncluded
			practiceBallsIncluded
			minPlayers
			maxPlayers
			price { ...Currency }
		}
		troonSubscriptionRateDiscounts {
			troonAccess { ...Currency }
			troonAccessPlus { ...Currency }
		}
		paymentInfo {
			rewardPointsEarned
			receipt {
				...PaymentInfo
				total { ...Currency }
				dueNow { ...Currency }
				dueLater { ...Currency }
				items {
					label
					amount { ...Currency }
					itemType
				}
			}
		}
		cancellationInfo {
			displayMessage
		}
		course {
			id
			name
			requiresCCAtBooking
			supportsTroonRewards
		}
		players
		holes
	}
}
`);

const getPaymentInfo = cachedQuery(paymentInfoQuery, {
	onError(error) {
		if (!error.message.includes('for tee time in the past')) {
			captureException(error);
		}
		return null;
	},
});

const cardQuery = gql(`
query paymentMethods {
	creditCards {
		id
		lastFour
		brand
	}
}`);

const getCreditCards = cachedQuery(cardQuery);
